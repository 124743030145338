import axios from 'axios';
import {
    GET_USERS_URL,
    GET_AGENTS_URL,
    GET_FARMERS_URL,
    COMPANY_ID_URL,
    SIGNIN_URL,
    REGISTER_ADMIN_URL,
    COMPANY_DATA_URL,
    REGISTER_COMPANY_URL,
    USER_DATA_URL,
    GET_COMPANIES_URL,
    VERIFY_COMPANY_URL,
    CROP_URL, UNVERIFY_COMPANY_URL
} from './index';
import cogoToast from 'cogo-toast';
import {SET_AUTH_USER, UNSET_USER} from '../stores/auth';
import setAuthToken from "../utils/setAuthToken";
import errorHandler from "../utils/errorHandler";

export const login = (data) => {
    return function(dispatch) {
        //Save to localStorage
        return axios.post(SIGNIN_URL, data).then((response) => {
            const token = `Token ${response.data.token}`;

            //Set token to localStorage
            localStorage.setItem('jwtToken', token);

            //Set token to Auth header
            setAuthToken(token);

            dispatch(SET_AUTH_USER({user: response.data, isAuthenticated: true}));
        }).catch((error) => {
            console.log(error.response)
            cogoToast.error(error.response.data.non_field_errors[0], {position: 'top-right', hideAfter: 2});
            return {
                error: true,
                payload: null
            };
        });
    }
};

export const logoutUser = () => {
    return function(dispatch) {
        //Remove toke from localStorage
        localStorage.clear();

        //Remove auth header for future requests
        setAuthToken(false);

        //Set current user to {} which will set isAuthenticated to false
        dispatch(UNSET_USER());
    }
};

export const getGlobal = () => {
    return axios.all([
        axios.get(GET_USERS_URL),
        axios.get(GET_COMPANIES_URL),
        axios.get(GET_FARMERS_URL),
        axios.get(GET_AGENTS_URL)
    ]).then(axios.spread((response1, response2, response3, response4) => {
        // Multiple requests are now complete
        return {
            users: response1.data,
            company: response2.data,
            farmers: response3.data,
            agents: response4.data
        }
    })).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const registerAdmin = (data) => {
    return axios.post(REGISTER_ADMIN_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const actionAdmin = (method, id, data) => {
    return axios({url: USER_DATA_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const registerCompany = (data) => {
    return axios.post(REGISTER_COMPANY_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const actionCompany = (method, id, data) => {
    return axios({url: COMPANY_DATA_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const verifyCompany = (method, id, data) => {
    return axios({url: VERIFY_COMPANY_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            payload: err.response.statusText,
            error: true
        }
    });
}

export const deactivateCompany = (method, id, data) => {
    return axios({url: UNVERIFY_COMPANY_URL(id), method: method, data: data}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            payload: err.response.statusText,
            error: true
        }
    });
}

export const getCompanyID = (id) => {
    return axios({url: COMPANY_ID_URL(id), method: "get"}).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

export const createCrop = (data) => {
    return axios.post(CROP_URL, data).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch(() => {
        return {
            payload: null,
            error: true
        }
    });
}

import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Dashboard from "../../pages/authorized/Dashboard";
import Access from "../../pages/authorized/Access";
import Crop from "../../pages/authorized/Crop";
import Company from "../../pages/authorized/Company";
import User from "../../pages/authorized/User";
import CompanyDetail from "../../pages/authorized/CompanyDetail";
import Scheme from "../../pages/authorized/Scheme";
import SchemeApplication from "../../pages/authorized/SchemeApplication";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/company" component={Company} />
                <PrivateRoute exact path="/company/:id" component={CompanyDetail} />
                <PrivateRoute exact path="/user" component={User} />
                <PrivateRoute exact path="/crop" component={Crop} />
                <PrivateRoute exact path="/access" component={Access} />
                <PrivateRoute exact path="/scheme" component={Scheme} />
                <PrivateRoute exact path="/scheme_application" component={SchemeApplication} />
                <PrivateRoute exact path="/error" component={PageError} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;

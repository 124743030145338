const BASE_URL = 'https://feip.app/backend/api/v1';

exports.SIGNIN_URL = `${BASE_URL}/login/`;
exports.GET_OUTGROWER_URL = `${BASE_URL}/outgrowerscheme/`;
exports.GET_OUTGROWER_SCHEME_URL = `${BASE_URL}/outgrowerscheme/`;
exports.GET_SCHEME_APPLICATION_URL = `${BASE_URL}/get_scheme_applications/`;
exports.GET_FARMERS_URL = `${BASE_URL}/farmer/`;
exports.GET_FARM_URL = `${BASE_URL}/farm/`;
exports.GET_USERS_URL = `${BASE_URL}/userobject/`;
exports.GET_USERS_ROLE_URL = (role) => `${BASE_URL}/get_user/?role=${role}`;
exports.SEARCH_FARMER_URL = (query) => `${BASE_URL}/search_users/?q=${query}`;
exports.GET_AGENTS_URL = `${BASE_URL}/agent/`;
exports.GET_COMPANIES_URL = `${BASE_URL}/company/`;
exports.VERIFY_COMPANY_URL = (id) => `${BASE_URL}/verify_company/${id}/`;
exports.UNVERIFY_COMPANY_URL = (id) => `${BASE_URL}/unverify_company/${id}/`;
exports.REGISTER_ADMIN_URL = `${BASE_URL}/register/`;
exports.CROP_URL = `${BASE_URL}/crops/`;
exports.REGISTER_COMPANY_URL = `${BASE_URL}/company/`;
exports.SEARCH_COMPANY_URL = (query) => `${BASE_URL}/search_company/?q=${query}`;
exports.COMPANY_SCHEME_URL = `${BASE_URL}/get_schemes_by_company_id/`;
exports.COMPANY_AGENT_URL = `${BASE_URL}/get_agents_by_company_id/`;
exports.COMPANY_FARMERS_URL = `${BASE_URL}/get_farmers_by_company_id/`;
exports.COMPANY_AGRONOMIST_URL = `${BASE_URL}/agronomist_by_company_id/`;
exports.COMPANY_AGRODEALER_URL = `${BASE_URL}/agrodealer_by_company_id/`;
exports.GET_CROPS_URL = `${BASE_URL}/crops/`;
exports.SEARCH_AGENT_URL = (id, query) => `${BASE_URL}/search/agents_in_company/?q=${query}&pacra_id=${id}/`;
exports.SEARCH_SCHEME_URL = (query) => `${BASE_URL}/search/schemes/?q=${query}`;
exports.SEARCH_AGRODEALER_URL = (query) => `${BASE_URL}/search/agrodealers/?q=${query}`;
exports.COMPANY_DATA_URL = (id) => `${BASE_URL}/company/${id}/`;
exports.USER_DATA_URL = (id) => `${BASE_URL}/userobject/${id}/`;
exports.COMPANY_ID_URL = (id) => `${BASE_URL}/company/${id}/`;

import React, {useEffect, useState} from 'react';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-responsive';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";
import cogoToast from "cogo-toast";
import {COMPANY_SCHEME_URL, GET_OUTGROWER_SCHEME_URL, SEARCH_SCHEME_URL} from "../../api";

const Scheme = () => {

    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [editId, setEditId] = useState(null);

    const token = localStorage.getItem('jwtToken');

    const {user} = useSelector((state) => state.auth.user);

    useEffect(() => {
        let table = $('#scheme_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Scheme Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax($('.dataTables_filter input').val().length > 0 ? SEARCH_SCHEME_URL(user.company.pacra_id, $('.dataTables_filter input').val()) : `${GET_OUTGROWER_SCHEME_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    success : function(res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.scheme_name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.crops.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_crop">${data.crops.length} - View Crops</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.inputs.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_input">${data.inputs.length} - View Inputs</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.prerequisites.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_prerequisites">${data.prerequisites.length} - View Prerequisites</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_crop', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.crops.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Crop ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Crop Type:</strong> <span>${item.crop_type || "Not Set"}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_input', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.inputs.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Input ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Input Type:</strong> <span>${item.input_type || "Not Set"}</span></p>
                    <p><strong>Metric:</strong> <span>${item.metric || "Not Set"}</span></p>
                    <p><strong>Price:</strong> <span>${item.price}</span></p>
                    <p><strong>Unit:</strong> <span>${item.unit}</span></p>
                    <p><strong>Currency:</strong> <span>${item.currency}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_prerequisites', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.prerequisites.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Prerequisites ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Type:</strong> <span>${item.type}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Value:</strong> <span>${item.value}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

    }, [])

    const reloadTable = () => {
        $('#agent_table').DataTable().ajax.reload(null, false);
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#scheme_form').parsley();
        $("#modalSchemeForm").modal({ backdrop: "static", keyboard: false }, "show");
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = () => {
        $('#scheme_data').empty();
        $(`#modalScheme`).modal("hide");
        $(".modal-backdrop").remove();
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Schemes</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Schemes</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Schemes</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="scheme_table" className="table table-hover" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Scheme Name</th>
                                        <th className="wd-20p">Crops</th>
                                        <th className="wd-20p">Inputs</th>
                                        <th className="wd-20p">Prerequisites</th>
                                        <th className="wd-20p">Date Created</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="title">User Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">User full information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div className="col-md-12">
                                        <p><strong>ID Number:</strong> <span id="id_number">Blank</span></p>
                                        <p><strong>First Name:</strong> <span id="first_name">Blank</span></p>
                                        <p><strong>Last Name:</strong> <span id="last_name">Blank</span></p>
                                        <p><strong>Username:</strong> <span id="username">Blank</span></p>
                                        <p><strong>Phone Number:</strong> <span id="phone_number">Blank</span></p>
                                        <p><strong>Email Address:</strong> <span id="email_address">Blank</span></p>
                                        <p><strong>Verified:</strong> <span id="verified">Blank</span></p>
                                        <p><strong>Active:</strong> <span id="active">Blank</span></p>
                                        <p><strong>Date Joined:</strong> <span id="date_joined">Blank</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalScheme" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                <div className="media-body">
                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" onClick={onCloseModal.bind()}>×</span></a>
                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="scheme_title">Scheme Detail</h4>
                                    <p className="tx-13 tx-color-02 mg-b-0">Scheme information.</p>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-10 pd-sm-b-40 pd-sm-x-30">
                                <div className="row row-sm">
                                    <div id="scheme_data" className="col-md-12">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Scheme;

import React, {useEffect, useState} from 'react';
import {getCompanyID} from "../../api/web";
import $ from "jquery";
import moment from "moment";
import {
    COMPANY_SCHEME_URL,
    COMPANY_AGENT_URL,
    COMPANY_AGRONOMIST_URL,
    COMPANY_AGRODEALER_URL,
    SEARCH_AGENT_URL,
    SEARCH_SCHEME_URL,
    SEARCH_AGRODEALER_URL
} from "../../api";
import cogoToast from "cogo-toast";

const CompanyDetail = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const [initialLoading, setInitialLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const [datatable] = useState({
        scheme: false,
        agent: false,
        farmer: false,
        agronomist: false,
        agrodealer: false
    });

    useEffect(() => {
        getCompanyID(id).then((response) => {
            if(!response.error) {
                setCompany(response.payload);
                setInitialLoading(false);
            }
        });
    }, []);

    const onVerifyCompany = () => {

    }

    const schemeDatatable = () => {
        if(datatable.scheme) return false;
        datatable.scheme = true;
        let table = $('#scheme_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Scheme Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax($('.dataTables_filter input').val().length > 0 ? SEARCH_SCHEME_URL(id, $('.dataTables_filter input').val()) : `${COMPANY_SCHEME_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    data: { pacra_id: id},
                    success : function(res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.scheme_name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.crops.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_crop">${data.crops.length} - View Crops</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.inputs.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_input">${data.inputs.length} - View Inputs</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.prerequisites.length ? `<nav class="nav nav-icon-only"><a href="#" class="nav-link view_prerequisites">${data.prerequisites.length} - View Prerequisites</a></nav>` : `<span class="tx-danger">No data</span>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.view_crop', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.crops.map((item, index) => {
                 $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Crop ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Crop Type:</strong> <span>${item.crop_type || "Not Set"}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
                 });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_input', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.inputs.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Input ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Name:</strong> <span>${item.name}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Input Type:</strong> <span>${item.input_type || "Not Set"}</span></p>
                    <p><strong>Metric:</strong> <span>${item.metric || "Not Set"}</span></p>
                    <p><strong>Price:</strong> <span>${item.price}</span></p>
                    <p><strong>Unit:</strong> <span>${item.unit}</span></p>
                    <p><strong>Currency:</strong> <span>${item.currency}</span></p>
                    <p><strong>Date Created:</strong> <span>${moment(item.created_at).format("Do MMM, YYYY")}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });

        table.on('click', '.view_prerequisites', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            $("#scheme_title").text(`${extract_td.raw.scheme_name} Scheme`);

            extract_td.raw.prerequisites.map((item, index) => {
                $("#scheme_data").append(`
                    <p><h5 class="tx-primary">Prerequisites ${index+1}</h5></p>
                    <p><strong>ID:</strong> <span>${item.id}</span></p>
                    <p><strong>Type:</strong> <span>${item.type}</span></p>
                    <p><strong>Description:</strong> <span>${item.description}</span></p>
                    <p><strong>Value:</strong> <span>${item.value}</span></p>
                    <hr/>`)
            });

            $("#modalScheme").modal({ backdrop: "static", keyboard: false }, "show");
        });
    }

    const agentDatatable = () => {
        if(datatable.agent) return false;
        datatable.agent = true;
        let table = $('#agent_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Agent Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax($('.dataTables_filter input').val().length > 0 ? SEARCH_AGENT_URL(id, $('.dataTables_filter input').val()) : `${COMPANY_AGENT_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    data: { pacra_id: id},
                    success : function(res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.username;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.first_name ? `${data.first_name} ${data.last_name}` : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.email || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Verified</span>`,
                            false : `<span class="badge badge-danger tx-white">Unverified</span>`
                        }[data.verified];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#agent_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#title").text("Agent Details");
            $("#id_number").text(extract_data.raw.nrc_number);
            $("#first_name").text(extract_data.raw.first_name);
            $("#last_name").text(extract_data.raw.last_name);
            $("#username").text(extract_data.raw.username);
            $("#phone_number").text(extract_data.raw.phone_number);
            $("#email_address").text(extract_data.raw.email);
            $("#verified").text(extract_data.raw.verified ? "Yes" : "No");
            $("#active").text(extract_data.raw.is_active ? "Yes" : "No");
            $("#date_joined").text(moment(extract_data.raw.date_joined).format("Do MMM, YYYY"));

            $("#modalUser").modal("show");
        });
    }

    const agronomistDatatable = () => {
        if(datatable.agronomist) return false;
        datatable.agronomist = true;
        let table = $('#agronomist_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Agronomist Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(`${COMPANY_AGRONOMIST_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    data: { pacra_id: id},
                    success: function (res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.username;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.first_name ? `${data.first_name} ${data.last_name}` : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.email || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Verified</span>`,
                            false : `<span class="badge badge-danger tx-white">Unverified</span>`
                        }[data.verified];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#agronomist_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#title").text("Agronomist Details");
            $("#id_number").text(extract_data.raw.nrc_number);
            $("#first_name").text(extract_data.raw.first_name);
            $("#last_name").text(extract_data.raw.last_name);
            $("#username").text(extract_data.raw.username);
            $("#phone_number").text(extract_data.raw.phone_number);
            $("#email_address").text(extract_data.raw.email);
            $("#verified").text(extract_data.raw.verified ? "Yes" : "No");
            $("#active").text(extract_data.raw.is_active ? "Yes" : "No");
            $("#date_joined").text(moment(extract_data.raw.date_joined).format("Do MMM, YYYY"));

            $("#modalUser").modal("show");
        });
    }

    const agroDealerDatatable = () => {
        if(datatable.agrodealer) return false;
        datatable.agrodealer = true;
        let table = $('#agro_dealer_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Agro-Dealer Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax($('.dataTables_filter input').val().length > 0 ? SEARCH_AGRODEALER_URL(id, $('.dataTables_filter input').val()) : `${COMPANY_AGRODEALER_URL}?page=${(data.length + data.start) / data.length}`, {
                    type: 'GET',
                    headers: {
                        Authorization: token
                    },
                    data: { q: id},
                    success: function (res) {
                        let result = [];

                        res.results.map((data) => {
                            result.push({
                                raw: data
                            });
                        });

                        callback({
                            recordsTotal: res.count,
                            recordsFiltered: res.count,
                            data: result
                        });
                    },
                    error: function(err) {
                        cogoToast.error(err.statusText, {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.username;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.first_name ? `${data.first_name} ${data.last_name}` : "not set";
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.email || "not set";
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            true : `<span class="badge badge-success tx-white">Verified</span>`,
                            false : `<span class="badge badge-danger tx-white">Unverified</span>`
                        }[data.verified];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date_added).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });

        $("#agro_dealer_table tbody").on("click", "tr", function () {
            let extract_data = table.row(this).data();

            $("#title").text("Agro-Dealer Details");
            $("#id_number").text(extract_data.raw.nrc_number);
            $("#first_name").text(extract_data.raw.first_name);
            $("#last_name").text(extract_data.raw.last_name);
            $("#username").text(extract_data.raw.username);
            $("#phone_number").text(extract_data.raw.phone_number);
            $("#email_address").text(extract_data.raw.email);
            $("#verified").text(extract_data.raw.verified ? "Yes" : "No");
            $("#active").text(extract_data.raw.is_active ? "Yes" : "No");
            $("#date_joined").text(moment(extract_data.raw.date_joined).format("Do MMM, YYYY"));

            $("#modalUser").modal("show");
        });
    }

    const onCloseModal = () => {
        $('#scheme_data').empty();
        $(`#modalScheme`).modal("hide");
        $(".modal-backdrop").remove();
    };

    if(!initialLoading) {
        return (
            <div className="content-body">
                <div className="container pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Company</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Company Details</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-5 tx-spacing--1" id="companyName">{company.name}</h4>
                            <div className="d-flex">
                                <h6 className="mg-b-0 tx-spacing--1 mr-1">Pacra ID: {company.pacra_id}</h6>
                                {company.is_verified ? <span className="badge badge-success tx-white">Verified</span> : <span className="badge badge-danger tx-white">Unverified</span>}
                            </div>
                        </div>
                        <div className="d-none d-md-block">
                            {company.is_verified ? <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none"><i className="wd-10 mg-r-5 fa fa-check"></i> Verified</button> : <button className="btn btn-sm pd-x-15 btn-brand-01 btn-uppercase mg-l-5 outline-none" onClick={onVerifyCompany.bind()}><i className="wd-10 mg-r-5 fa fa-check"></i> Verify</button>}
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Information</a></li>
                                        <li className="nav-item"><a href="#scheme" className="nav-link" data-toggle="tab" onClick={schemeDatatable.bind()}><i className="ion-md-book"/> Schemes</a></li>
                                        <li className="nav-item"><a href="#agent" className="nav-link" data-toggle="tab" onClick={agentDatatable.bind()}><i className="ion-md-person"/> Agents</a></li>
                                        <li className="nav-item"><a href="#agronomist" className="nav-link" data-toggle="tab" onClick={agronomistDatatable.bind()}><i className="ion-md-person"/> Agronomist</a></li>
                                        <li className="nav-item"><a href="#agrodealer" className="nav-link" data-toggle="tab" onClick={agroDealerDatatable.bind()}><i className="ion-md-person"/> Agro-dealer</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>TPIN #:</strong> {company.tpin}</p>
                                            <p><strong>Company UUID:</strong> {company.company_uuid}</p>
                                            <p><strong>Address:</strong> {company.address || "Not applicable"}</p>
                                            <p><strong>Phone Number:</strong> {company.phone_number || "Not applicable"}</p>
                                            <p><strong>Email:</strong> {company.email || "Not applicable"}</p>
                                            <p><strong>Date:</strong> {moment(company.date_added).format("Do MMM, YYYY hh:mm")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="scheme" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Schemes</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#scheme_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="scheme_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Scheme Name</th>
                                                            <th className="wd-20p"># of Crops</th>
                                                            <th className="wd-20p"># of Inputs</th>
                                                            <th className="wd-20p"># of Mechanical</th>
                                                            <th className="wd-20p">Date Created</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="agent" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Agents</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#agent_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="agent_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Username</th>
                                                            <th className="wd-25p">Full Name</th>
                                                            <th className="wd-25p">Email</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="agronomist" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Agronomists</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#agronomist_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="agronomist_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Username</th>
                                                            <th className="wd-25p">Full Name</th>
                                                            <th className="wd-25p">Email</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="agrodealer" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Agro-Dealer</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10" onClick={() => $('#agro_dealer_table').DataTable().ajax.reload()}><i className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="agro_dealer_table" className="table table-hover" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Username</th>
                                                            <th className="wd-25p">Full Name</th>
                                                            <th className="wd-25p">Email</th>
                                                            <th className="wd-10p">Status</th>
                                                            <th className="wd-15p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                                <div className="media-body">
                                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="title">User Detail</h4>
                                                    <p className="tx-13 tx-color-02 mg-b-0">User full information.</p>
                                                </div>
                                            </div>
                                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                                <div className="row row-sm">
                                                    <div className="col-md-12">
                                                        <p><strong>ID Number:</strong> <span id="id_number">Blank</span></p>
                                                        <p><strong>First Name:</strong> <span id="first_name">Blank</span></p>
                                                        <p><strong>Last Name:</strong> <span id="last_name">Blank</span></p>
                                                        <p><strong>Username:</strong> <span id="username">Blank</span></p>
                                                        <p><strong>Phone Number:</strong> <span id="phone_number">Blank</span></p>
                                                        <p><strong>Email Address:</strong> <span id="email_address">Blank</span></p>
                                                        <p><strong>Verified:</strong> <span id="verified">Blank</span></p>
                                                        <p><strong>Active:</strong> <span id="active">Blank</span></p>
                                                        <p><strong>Date Joined:</strong> <span id="date_joined">Blank</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="modalScheme" tabIndex="-1" role="dialog" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>
                                                <div className="media-body">
                                                    <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" onClick={onCloseModal.bind()}>×</span></a>
                                                    <h4 className="tx-18 tx-sm-20 mg-b-2" id="scheme_title">Scheme Detail</h4>
                                                    <p className="tx-13 tx-color-02 mg-b-0">Scheme information.</p>
                                                </div>
                                            </div>
                                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                                <div className="row row-sm">
                                                    <div id="scheme_data" className="col-md-12">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default CompanyDetail;
